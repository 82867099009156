@import "../../styles/utils";

.subNav {
  height: 50px;
  background: #DB0001;
  display: flex;
  align-items: center;
  padding: 0 24rem 1rem 24rem;
  width: 100%;

  img {
    margin-bottom: 0.5rem;
  }

  p {
    display: flex;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 0;
  }

  h3 {
    color: #FFF;
    font-size: 12px;
  }

  .airports, .passenger {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
  }

  .depRet {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    border-right: 1px solid white;
    border-left: 1px solid white;
    padding-left: 27px;
    margin-right: 27px;
  }
}

.booking-steps {
  height: 50px;
  background: #F5F5F5 !important;
  width: 100%;
}

.book-flight-side-nav {
  flex: 1;
  max-width: 30rem;
  background-color: $grey;
  padding-top: 10rem;
  height: 100vh;

  .ant-collapse {
    .ant-collapse-item {
      .ant-collapse-header {
        color: blue;
        font-weight: bold;

        .ant-collapse-arrow {
          padding: 10px 0;
        }
      }
    }

    .ant-collapse-item-active {
      .ant-collapse-header {
        color: orange;
      }
    }
  }

  @include medium {
    display: none;
  }
}

.book-flight-content {
  flex: 3;
  position: relative;
  height: 100vh;
  @include small {
    height: calc(100vh - 130px);
  }
}

.book-flight-padding-container {
  padding: 3rem;
}

.flights-container {
  position: relative;
  height: 100vh;
  padding: 1rem 10rem;

  @include small {
    padding: 0 !important;
  }

  @include large {
    padding: 1rem 5rem;
  }
}

.flights-padding-container {
  margin: 0;
  padding-bottom: 200px;

  @include small {
    padding: 0 20px 200px 20px;
  }
}

.flights-page-back {
  display: flex;
  align-items: center;

  height: 2rem;
  margin: 2rem 0 5rem;

  cursor: pointer;
  color: #DB0001;
  font-size: 1.6rem;
  font-weight: bold;

  svg {
    margin-right: 1.4rem;
    transform: rotate(180deg) scale(1.1);

    path {
      fill: #DB0001;
    }
  }
}

.flights-page-header {
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  p {
    color: #DB0001;
    margin-bottom: 0;
  }

  .subtitle {
    display: flex;
    align-items: center;
    font-size: 38px;
    font-weight: bold;

    svg {
      width: 33px;
    }
  }
}

.nav-bar {
  display: flex;
  height: 145px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #F7F7F9;
  justify-content: space-between;
  color: #DB0001;
  font-size: 35px;
  font-weight: bold;
  padding: 20px 100px;
  align-items: center;

  @include small {
    background-color: #F7F7F9 !important;
    display: block;
    padding: 0;
    flex-direction: column;
    justify-content: space-evenly;
  }

  p {
    color: black;
    font-weight: lighter;
    font-size: 14px;

    @include small {
      margin-left: 0;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 0;
    }
  }

  @include small {
    background-color: white;
    font-size: 1.7rem;
    width: 100%;
  }
}

.nav-buttons {
  display: flex;
  justify-content: center;
  align-items: center;

  @include mac13 {
    margin-right: 50px;
  }

  @include medium {
    margin-right: 20px;
    justify-content: space-around;
  }

  @include small {
    margin: 0 25px;
  }
}

.ant-steps {
  align-items: center;
  padding: 21px 131px;

  @include medium {
    padding: 20px;
  }

  @include small {
    padding: 21px 10px;
  }
}

.spinner-center {
  position: absolute;
  top: 40%;
  left: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info-text {
  color: rgba(0, 0, 0, 0.6);
  padding-top: 1rem;
}

.buttonStyle {
  background: white;
  border: transparent;
  width: 30px;
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.15);
}

.drawer .anticon svg {
  display: none !important;
}

.grandPrice {
  padding-top: 15px;

  @include small {
    padding: 15px 25px 0;
  }

  p {
    color: #000;
  }

  h1 {
    color: #DB0001;
    font-weight: bold;
    font-size: 36px;

    @include small {
      font-size: 28px;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
  }
}