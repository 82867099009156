@import "../../styles/utils";

.faqComponent {
  text-align: center;
  min-height: 70rem;
  margin: 0 70px;

  @include small {
    margin: 0 37px 43px;
    min-height: 0;
  }

  h1 {
    font-size: 34px;
    margin-bottom: 70px;

    @include small {
      font-size: 23px;
      font-weight: bold;
      margin-bottom: 26px;
    }
  }
}

.dropdowns {
  display: flex;
  justify-content: space-evenly;

  @include small {
    display: block;
  }
}

.menuStyle {
  background: white;
  border: none;
  box-shadow: none;
  list-style: none;
  margin-top: 35px;
  font-size: 20px;

  @include small {
    margin-top: 0;
  }
}

.menuItemStyle {
  display: flex;
  justify-content: space-between;
  width: 124rem;
  height: 83px !important;
  background: #F5F5F5 !important;
  margin-top: 10px;
  padding: 30px 24px 25px 24px;
  line-height: 24px;

  @include large {
    width: 83rem;
  }

  span {
    font-size: 20px;
    color: #DB0001;
    padding-top: 3px;
    font-weight: 400;

    @include medium {
      font-size: 15px;
    }
  }

  p {
    margin-bottom: 0 !important;
    font-size: 20px;

    @include medium {
      font-size: 15px;
    }
  }
}

.space {
  font-size: 20px;
  color: #7E7C7C;

  @include medium {
    font-size: 15px;
  }

  @include small {
    font-size: 18px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 28px;
  }

  span {
    height: 24px;
    font-size: 16px;
  }

  &:hover {
    border-bottom: 1px solid #DB0001;
    color: #DB0001;
  }
}

.collapsedQuestion {
  margin-top: 10px;
  width: 124rem;
  height: 100%;
  background: white;
  position: absolute;
  overflow: scroll;

  p {
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    margin-top: 35px;
  }
}