
.make-payment_container {
  position: relative;
  height: 100%;
  .make-payment {
    position: relative;
    padding: 3rem 3rem 8rem 3rem;
    height: 100%;
    overflow: scroll;

    h2 {
      font-weight: bold;
      font-size: 2.5rem;
      line-height: 27px;
      color: #374665;
    }
  }
}
