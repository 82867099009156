@import './assets/styles/colors.scss';
@import './assets/styles/global.scss';
@import '~antd/dist/antd.css';
@import './assets/styles/ant-design.scss';


body {
  font-family: 'Product-Sans', sans-serif;
}

.opacity-60 {
  opacity: .6;
}

/* Background colors */
.app-bg-primary { background-color: $sky; }

/* Text colors */
.app-text-main { color: #374665; }
.app-text-secondary { color: #06A0B8; }


