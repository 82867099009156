@import "../../styles/utils";

.homepagePassengersDropdown {
  width: 400px !important;
  left: 1130px !important;

  h3 {
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: #DB0001;
    margin: 20px 0 40px 24px;
  }

  @include medium {
    padding: 0;
  }

  .passengerDropdown {
    display: flex;
    justify-content: space-between;
    padding: 0;
    border-bottom: 0.6px solid #DB0001;
    margin: 20px 26px 15px 26px;
    height: 30px;
  }

  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 15px;
    color: #000000;

    span {
      font-weight: 400;
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.4rem;

    @include medium {
      padding: 0 2.2rem;
    }

    input {
      border: none;
      width: 52px;
      padding-left: 15px;
      color: #000;
      font-size: 18px;
      text-align: center;
      display: flex;
      justify-content: center;
    }

    button {
      color: #DB0001;
      font-size: 22px;
      border: none;
      padding: 0;
      background: transparent;

      &:disabled {
        opacity: .5;
      }
    }
  }

  p {
    width: 220px;
    font-size: 10px;
    line-height: 12px;
    color: #7E7C7C;
    padding: 10px 0 50px 26px;
  }
}

.header {
  display: none;
  align-items: center;

  padding: 2.2rem;
  margin-bottom: 4rem;
  background-color: #0D3B66 !important;

  color: white;
  font-size: 2.2rem;
  font-weight: bold;

  svg {
    margin-right: 2rem;
  }

  @include medium {
    display: flex;
  }
}