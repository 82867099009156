@import "../../styles/utils";

.footer {
  height: 504px;
  background: #DB0001;
  padding: 50px 150px;

  @include medium {
    padding: 50px;
  }

  @include small {
    padding: 20px;
  }

  ul {
    list-style: none;
  }

  li {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: white;
    margin-bottom: 10px;

    @include medium {
      font-size: 10px;
    }

    @include small {
      display: none;
    }
  }

  h1{
    font-size: 44px;
    font-weight: 700;
    color: white;
    line-height: 53px;
    margin-bottom: 40px;
  }

  h3 {
    color: white;

    @include medium {
      font-size: 1.3rem;
    }
  }

  p {
    width: 271px;
    font-size: 14px;
    color: white;
    margin-bottom: 40px;

    @include medium {
      width: 200px;
      font-size: 14px;
    }

    @include small {
      padding-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.socialMedia {
  ul {
    display: flex;

    @include small {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  img {
    padding: 0 5px 10px 5px;
  }
}

.footerContent {
  margin-right: 200px;

  @include large {
    margin-right: 0;
  };

  @include medium {
    margin-right: 0;
  };
}

.footerPadding {
  margin-right: 50px;

  @include medium {
    margin-right: 0;
  }
}