@import "../../styles/utils";

.account-container {


  .ant-form-item input:focus + .ant-form-item-required {
      color: #4b6cc4!important;
    font-size: 2rem;
    border: 4px solid red;

  }
  .ant-form-item-required {
    font-size: 1.5rem!important;
    font-weight: 600!important;
    color: #5f626a;
  }


  width: 80%;
  margin: 5rem auto;

  @include medium {
    width: 100%;
  }

  .right-column {
    margin-top: 0;
  }
}

.details:hover {
  color: #40a9ff!important;
  label.details {
    color: #40a9ff!important;
  }
}

.account-title {
  display: flex;
  align-items: center;

  cursor: pointer;
  height: 3rem;
  margin-bottom: 1rem;

  color: #0D3B66;
  font-size: 2.8rem;
  font-weight: bold;

  svg {
    margin-right: 2rem;
    margin-left: 2rem;
    transform: scale(1);

    path {
      fill: #0D3B66;
    }
  }
}


.account-image {
  width: 100%;
  margin-bottom: 3rem;
  img {
    max-width: 100%;
  }
}

.account-name {
  font-weight: bold;
  font-size: 2.8rem;
  line-height: 3.4rem;
  color: #0D3B66;
}

.account-section {
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #06A0B8;
  margin-top: 4rem;
}

.account-label {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #374665;
  opacity: .6;
  margin-top: 3rem;
}

.account-value {
  font-weight: bold;
  font-size: 1.8rem;
  color: #374665;
}

.account-edit-button {
  background: #F3FAFB;
  border-radius: 10px;
  padding: 1.2rem 1.6rem;

  font-size: 2rem;
  line-height: 2.4rem;
  display: flex;
  align-items: center;
  text-align: center;

  color: #06A0B8;

  border: none;

  svg {
    margin-right: 1rem;
  }
}
