@import "../../styles/utils";
@import "../../styles/antd-custom";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 8rem;
  padding: 0 18rem 0 2rem;
  z-index: 1;
  background: #DB0001;

  h1 {
    color: white;
    font-size: 44px;
    font-weight: 700;
    margin-left: 200px;

    :hover {
      text-decoration: white !important;
    }
  }

  @include large {
    height: 7.4rem;
    padding: 0 3rem 0 2rem;

    .headerNavigation {
      right: 100vw;
    }

    &.expanded {
      .headerNavigation {
        right: 0;
      }
    }
  }
}

.closeIcon {
  display: none;
}

.barsIcon {
  display: none;
  cursor: pointer;
  transform: scale(1.2);

  @include large {
    display: block;
  }
}

.headerNavigation {
  transition: right .2s linear;

  ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    margin-right: 3rem;
    font-weight: bold;

    @include large {
      justify-content: space-between;
      margin-top: 20px;
    }

    li {
      margin-left: 5rem;

      a, a:hover {
        font-size: 20px;
        color: white;
      }
    }

    span {
      margin-top: 5px;
      margin-left: 80px;
      font-size: 10px;
      font-weight: 700;
      color: white !important;
    };
  }

  @include large {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding: 3rem;
    margin: 0;
    z-index: 9999;
    background-color: #DB0001;

    .closeIcon {
      display: block;
      cursor: pointer;
      margin-left: auto;
      transform: scale(1.2);
    }
  }
}

.headerDropdownMenuOverlay {
  width: 12rem;
  margin-left: 80px !important;
  @include large {
    z-index: 9999;
  }
}
