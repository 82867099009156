@import "../../styles/utils";

.passengerFormRow {
  display: flex;
  flex-wrap: wrap;
  max-width: 890px;

  input {
    width: 350px;

    @include small {
      width: 100%;
    }
  }
}

.passengerFormRowTitle {
  font-size: 30px;
  margin-bottom: 45px;
}

.col {
  width: 395px;
  height: 37px;
  position: relative;
  border: 1px solid #7E7C7C;
  border-radius: 5px;
  margin-right: 50px;
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  outline: none;
  background: transparent;
  padding: 9px;

  @include medium {
    margin-bottom: .8rem;
    border-radius: .8rem;
  }

  @include small {
    margin-right: 0;
    width: 100%;
    height: 40px;
    margin-bottom: 2.8rem;
    border-radius: .8rem;
  }

  &::placeholder {
    color: #7E7C7C;
  }

  //span {
  //  display: none;
  //}

  &.hasError {
    border-color: indianred;
  }
}

.passengerFormRowInput {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  border: none;
  outline: none;
  background: transparent;

  &::placeholder {
    color: #7E7C7C;
  }

  span {
    display: none;
  }
}

.validationMessage {
  font-size: 1.2rem;
  color: indianred;
  display: flex;
  //justify-content: end;
  margin-top: 6px;
}

.radioButtons {
  position: relative;

  @include small {
    margin-bottom: 2rem;
  }

  .validationMessage {
    left: 0;
    bottom: -.85rem;
  }

  &.hasError {
    input+span {
      border-color: indianred;
    }
  }
}

.infoMessage {
  display: flex;
  width: 350px;
  font-size: 10px;
  color: #7E7C7C;
  margin-top: 21px;

  img {
    margin-bottom: 25px;
    margin-right: 5px;
  }
}

.colContact {
  width: 395px;
  height: 37px;
  border: 1px solid #7E7C7C;
  border-radius: 5px;
  margin-right: 50px;
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  outline: none;
  background: transparent;
  padding-top: 3px;

  @include small {
    width: 100%;
    height: 40px;
    margin-right: 0;
    margin-bottom: 2.8rem !important;
    border-radius: .8rem;
  }

  @include medium {
    margin-bottom: .8rem;
    border-radius: .8rem;
  }

  :focus {
    border: none !important;
  }

  &.hasError {
    border-color: indianred;
  }
}

