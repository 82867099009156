@import "../../styles/utils";

.selectFlightContainer {
  position: relative;
  padding: 2rem;

  @include small {
    padding: 2rem 0;
  }
}

.flightBoxes {
  display: flex;
  justify-content: space-between;
  margin-right: 50px;
  margin-left: 50px;

  @include large {
    margin: 0 30px;
    overflow: hidden;
  }

  @include small {
    margin: 0 30px;
    overflow: hidden;
  }

}

.flightRows {
  margin-top: 2.4rem;

  @include small {
    margin-top: 0;
  }
}

.backButton,
.forwardButton {
  position: absolute;
  width: 48px;
  height: 60px;
  cursor: pointer;
  border: 0.6px solid #7E7C7C;
  border-radius: 8px;
  background: whitesmoke;

  @include large {
    width: 35px;
  }

  svg {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    path {
      fill: #7E7C7C;
    }
  }
}

.backButton {
  left: .8rem;

  @include small {
    left: 0;
  }

  svg {
    left: 48%;
    transform: translate(-50%, -50%) rotate(180deg);
  }
}

.forwardButton {
  right: .8rem;

  @include small {
    right: 0;
  }

  svg {
    left: 52%;
  }
}
