@import "../../styles/utils";

.flightRow {
  overflow: hidden;
  border-top: 1px solid #DB0001;
  border-bottom: 1px dashed #DB0001;

  @include small {
    border-top: none;
  }

  //@include medium {
  //  border-top: none;
  //}
}

.infoContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 0;
  flex-wrap: wrap;
  border-radius: 5px;
  margin: 2rem 0;

  @include small {
    padding: 0;
  }

  //@include medium {
  //  padding: 0;
  //}
}

.flightDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 2;

  @include small {
    padding: 0 10px;
  }

  .flightInfo {
    padding: 2rem 0;

    @include small {
      text-align: center;
      width: 100px;
      height: 180px;
    }

    //@include medium {
    //  text-align: center;
    //  width: 200px;
    //  height: 180px;
    //}
  }

  @include small {
    text-align: center;
  }

  //@include medium {
  //  text-align: center;
  //}

  .horizontal {
    position: relative;
    flex: 1;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      path {
        fill: #DB0001;
      }
    }

    .flightTime {
      position: absolute;
      top: 100%;
      left: 50%;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      transform: translate(-50%, 150%);

      @include large {
        transform: translate(-50%, 75%);
      }

      @include medium {
        display: none;
      }

      @include small {
        display: none;
      }
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      border-top: 1px solid #DB0001;
      width: 100%;
    }
  }

  .date {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    text-align: center;

    @include medium {
      font-size: 15px;
    }

    @include small {
      font-size: 15px;
    }
  }

  .time {
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    text-align: center;
    color: #DB0001;

    @include medium {
      font-size: 42px;
      line-height: 50px;
    }

    @include small {
      font-size: 36px;
      line-height: 48px;
    }
  }

  .airport {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    text-align: center;

    @include medium {
      font-size: 14px;
      font-weight: normal;
    }

    @include small {
      font-size: 14px;
      font-weight: normal;
    }
  }

  .point {
    @include medium {
      width: 9px;
      margin-top: 2px;
    }

    @include small {
      width: 7px;
      margin-top: 2px;
    }
  }
}

.selection {
  width: 133px;
  height: 142px;
  background: #DB0001;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 50px;

  @include large {
    width: 120px;
    height: 130px;
    margin-left: 20px;
  }

  @include small {
    width: 100%;
    height: 70px;
    flex-direction: row;
    justify-content: space-around;
    margin-left: 0;
    margin-bottom: 20px;
  }

  @include medium {
    width: 100%;
    height: 70px;
    flex-direction: row;
    justify-content: space-around;
    margin-left: 0;
    margin-bottom: 20px;
  }

  .select {
    width: 78.5px;
    height: 24.5px;
    border: 0.6px solid #FFFFFF;
    border-radius: 8px;
    background: transparent;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
  }

  h1 {
    font-size: 28px;
    color: #FFFFFF !important;
    font-weight: 500;
    text-align: center;

    @include large {
      font-size: 22px;
      margin-bottom: 0;
    }
  }

  h3 {
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF !important;
    margin-bottom: 0;
    text-align: center;

    @include small {
      font-size: 14px;
      line-height: 15px;
    }

    //@include medium {
    //  font-size: 14px;
    //  line-height: 15px;
    //}
  }

  p {
    margin-bottom: 7px;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
    text-align: center;

    @include small {
      margin-bottom: 0;
    }

    //@include medium {
    //  margin-bottom: 0;
    //}
  }
}

.disabled {
  position: relative;
  cursor: default;
  box-shadow: none;

  .top {
    border-bottom: 1px solid #DFDFDF;
  }

  .top, .bottom {
    background-color: #F7F7F9;
  }

  &:hover {
    box-shadow: none;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //opacity: .3;
    background-color: #F7F7F9;
    border: none;
    z-index: 99;
  }

  .sold {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem;
    color: white;
    font-size: 3rem;
    border-radius: 1rem;
    background-color: #DB0001;
    z-index: 999;
  }
}

.basicInfo {
  height: 175px;
  border-bottom: 1px solid #DB0001;
  padding: 15px;
  background: #F7F7F5;

  @include large {
    height: 140px;
  }

  @include small {
    height: 130px;
    padding: 10px;
  }

  //@include medium {
  //  height: 130px;
  //  padding: 10px;
  //}

  p {
    font-size: 20px;
    color: #B1B1B2;

    @include large {
      font-size: 15px;
    }

    @include small {
      font-size: 12px;
    }

    //@include medium {
    //  font-size: 14px;
    //}
  }

  h1 {
    font-weight: 700;
    font-size: 35px;
    color: #000000;
    margin-bottom: 14px;

    @include large {
      font-size: 28px;
    }

    @include small {
      font-size: 18px;
    }

    //@include medium {
    //  font-size: 20px;
    //}
  }

  h2 {
    font-weight: 700;
    font-size: 38px;
    color: #DB0001;

    @include large {
      font-size: 32px;
    }

    @include small {
      font-size: 22px;
      margin-bottom: 0;
    }

    //@include medium {
    //  font-size: 24px;
    //  margin-bottom: 0;
    //}
  }

  .benefits {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include small {
      justify-content: left;
    }


    div {
      display: flex;

      @include small {
        margin-right: 25px;
      }

      img {
        height: 21px;
        margin-right: 10px;
        margin-top: 3px;

        @include large {
          height: 18px;
        }

        @include small {
          margin-top: 0;
          margin-right: 3px;
        }

        //@include medium {
        //  margin-top: 0;
        //  margin-right: 3px;
        //}
      }
    }

    p {
      font-size: 19px;
      color: #000000;

      @include large {
        font-size: 14px;
      }

      @include small {
        font-size: 10px;
      }

      //@include medium {
      //  font-size: 12px;
      //}
    }
  }
}