@import "../../styles/utils";

.booking-details-container {
  width: 80%;
  margin: 5rem auto;

  @include medium {
    width: 100%;
  }

  .right-column {
    margin-top: 3.3rem;
  }
}

.booking-details-title {
  display: flex;
  align-items: center;

  height: 3rem;
  margin-bottom: 1rem;

  color: #0D3B66;
  font-size: 2.8rem;
  font-weight: bold;

  svg {
    margin-right: 2rem;
    margin-left: 2rem;
    transform: scale(1);

    path {
      fill: #0D3B66;
    }
  }
}

.expandable-row {
  max-height: 0;
  overflow: hidden;
  transition: max-height .2s ease-in-out;

  &.expanded {
    max-height: 65rem;

    @include medium {
      max-height: 125rem;
    }
  }
}
