@import "../../styles/utils";


.ant-modal-content {
  height: auto!important;
  overflow: auto!important;
}

.ant-modal {
  margin: auto!important;
}


.register-drawer-content {
  .ant-modal-content {
    height: auto!important;
    margin-right: 100px!important;
  }


}
@include small(){
  .ant-modal-content {
    height: auto!important;
    margin-right: 0!important;
  }
  .ant-modal {
    //margin-a: auto!important;
    margin:0 auto!important;
  }
}
.ant-modal {
  //margin-a: auto!important;
  margin:0;
  margin-left: auto;
}
.login-drawer-content,
.register-drawer-content {


  h1 {
    text-align: center;
    margin-bottom: 2.4rem;
  }

  a {
    color: #06A0B8;
  }
}

.login-drawer-content {
  width: 60%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include large {
    width: 80%;
  }
}

.register-drawer-content {
  width: 100%;

  @media (min-width: 992px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
  }

  @include large {
    margin-top: 4rem;
  }
}

.authentication-drawer {
  display: flex;
  align-items: center;
  justify-content: center;
}
