.terms-conditions-container{
  margin: 9rem 20rem 11rem;

  p{
    font-size: 1.6rem;
  }

  li{
    font-size: 1.5rem;
  }

  strong{
    font-size: 1.7rem;
    color: #374665;
  }

  h1{
    color: #374665;
  }
}