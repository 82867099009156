.passenger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  margin: 1rem 0;
  padding: 1.6rem;
  border-radius: 8px;
  box-shadow: 0px 3px 9px -1px rgba(133, 133, 133, 0.3);
  -webkit-box-shadow: 0px 3px 9px -1px rgba(133, 133, 133, 0.3);
  -moz-box-shadow: 0px 3px 9px -1px rgba(133, 133, 133, 0.3);
}

.subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */


  /* Gray - Dark */

  color: #374665;

  opacity: 0.6;

}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height, or 150% */

  margin-top: .6rem;

  /* Gray - Dark */

  color: #374665;
}

.arrow {
  transform: rotateX(180deg);
}

.rotate {
  transform: rotateX(0);
}
